<template>
  <div class="job-details">
    <div class="banner-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
                class="align-content-center col-12 d-flex flex-column justify-content-center banner-content">

              <h3>{{ job.title }}</h3>
              <div class="d-flex flex-column flex-md-row">
                <div class="d-flex align-items-center">
                  <i class="fas fa-suitcase me-2"></i>
                  <p>Job Type : {{ job.job_summery.job_type }}</p>
                </div>
                <div class="d-flex align-items-center">
                  <i class="far fa-clock ms-md-3 ms-0 me-2"></i>
                  <p>Deadline : <span> {{ job.job_summery.application_deadline }}</span></p>
                </div>
                <div class="d-flex align-items-center d-md-none">
                  <div class="ms-md-3 ms-0 me-2">
                    <i class="fas fa-hand-holding-usd"></i>
                  </div>
                  <p>Salary : <span> {{ job.job_summery.salary_range }}</span></p>
                </div>
              </div>
                <p class='email-text'>
                    For Any Queries Email :
                    <span class="text-warning"><b>career@mediusware.com</b></span>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="job-details-section">
      <div class="container">
        <div class="row">
          <div v-if="job.banner_image" class="col-md-12 pb-5 d-block d-sm-none">
            <img :src="job.banner_image" class="img-fluid" alt="">
          </div>
          <div class="col-md-8">
            <div class="left-section">
              <div class="video-container d-none d-sm-block">
                <iframe width="100%" height="315"
                        src="https://www.youtube.com/embed/zrt7SWJXriY?controls=0&autoplay=1&mute=1&rel=0&showinfo=0&loop=1&playlist=zrt7SWJXriY"
                        frameborder="0"
                        allowfullscreen></iframe>

              </div>

              <div class="experience  mb-36" v-for="context in job.job_contexts">
                <div class="title">{{ context.title }}</div>
                <div v-html="context.description"></div>
              </div>

              <router-link class="apply-btn"
                           :to="{ name : 'jobApply', params : $route.params.slug}">
                Apply Now
              </router-link>
            </div>
          </div>
          <div class="col-md-4">
            <div class="right-section">
              <h3>Key Information</h3>
              <div class="d-flex">
                <div class="flex-shrink-0" style="width:30%">Published on:</div>
                <div class="">{{ job.updated_at }}</div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0" style="width:30%">Vacancy:</div>
                <div class="">{{ job.job_summery.vacancy }}</div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0" style="width:30%">Experience:</div>
                <div class="">{{ job.job_summery.experience }}</div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0" style="width:30%">Salary:</div>
                <div class="">{{ job.job_summery.salary_range }}</div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0" style="width:30%">Job type:</div>
                <div class="">{{ job.job_summery.job_type }}</div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0" style="width:30%">Deadline:</div>
                <div class="">{{ job.job_summery.application_deadline }}</div>
              </div>
              <div class="d-flex align-items-baseline">
                <div class="flex-shrink-0" style="width:30%">Location:</div>
                <div class="" style="line-height: 20px">24/1, Taj Mahal Road, Shiya Masjid mor, Floor - 7th & 8th, Ring Road, 1207
                </div>
              </div>
              <router-link class="apply-btn" :to="{ name : 'jobApply', params : $route.params.slug}">
                Apply Now
              </router-link>
              <!--                            <div class="calender-section mb-2 pt-4">
                                            <div class="item-box float-md-end float-sm-end">
                                              <div class="video-section overflow-hidden shadow">
                                                <iframe width="100%" height="100%"
                                                        src="https://www.youtube.com/embed/R9myxRtFZvo?controls=1&autoplay=1&mute=1&rel=0&showinfo=0&loop=1&playlist=R9myxRtFZvo"
                                                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen>

                                                </iframe>
                                              </div>
                                            </div>
                                          </div>-->
              <div class="calender-section mb-2 pt-4">
                <div class="item-box float-md-end float-sm-end">
                  <div class="video-section overflow-hidden shadow">
                    <iframe width="100%" height="100%"
                            src="https://www.youtube.com/embed/KzNWlUFGOaU?controls=1&autoplay=1&mute=1&rel=0&showinfo=0&loop=1&playlist=KzNWlUFGOaU"
                            frameborder="0"
                            allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>
              <!--              <div class="share-job">
                  <h4>Share Job</h4>
                  <div class="social-icon">
                    <a href="" class="icon">
                      <img src="/assets/facebook-f-brands.svg" alt="">
                    </a>
                    <a href="" class="icon">
                      <img src="/assets/twitter-brands.svg" alt="">
                    </a>
                    <a href="" class="icon">
                      <img src="/assets/linkedin-in-brands.svg" alt="">
                    </a>
                    <a href="" class="icon">
                      <img src="/assets/skype-brands.svg" alt="">
                    </a>
                  </div>
                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- what-offer-section-->
    <what-offer/>
  </div>
</template>

<script>
import axios from "@/axios";
import WhatOffer from "../../components/whatOffer";

export default {
  name      : "JobDetails",
  components: {WhatOffer},
  data      : () => ({
    job: {
      job_summery: {}
    },
  }),
  mounted() {
    axios.get(`/job/${this.$route.params.slug}`).then(res => {
      this.job = res.data
      this.$nextTick(() => {
        document.title = this.job.title + "- Mediusware Ltd"
      })
      console.log(res.data)
    }).catch(err => {
      console.error(err)
    })
  }
}
</script>

<style lang="scss">
.title {
  font-size : 24px;
  color     : #0060AF
}

.video-section {
  height        : 236px;
  border-radius : 10px;
  @media only screen and (max-width : 991px) {
    height        : 130px;
  }
}

@media only screen and (max-width : 575px) {
  .title {
    font-size : 18px;
    color     : #0060AF;
  }
}
</style>