<template>
  <div class="offer-wrapper">
    <div class="offer-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>What we offer ?</h3>
          </div>
          <div class="col-lg-3 col-md-6" v-for="(offer, index) in offers">
            <div class="offer-box">
              <div class="img">
                <img class="img-fluid" :src="`/assets/${offer.img}.svg`" alt="">

              </div>
              <div class="title">{{ offer.title }}</div>
              <p>
                <!--                You can always take a tea or coffee break and enjoy your time with your colleague.-->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whatOffer",

  data: () => ({
    offers: [
      { title: 'Boost yourself with unlimited coffee.', img: 'coffee' },
      { title: 'Enjoy your lunch with everyone.', img: 'lunch' },
      { title: 'try some evening snack with everyone!', img: 'nachos' },
      { title: 'Refresh your mind by playing game!', img: 'game' },
      { title: 'Spend weekend with your Family.', img: 'holiday' },
      { title: 'You will never get bored in here.', img: 'best' },
      { title: 'Keep yourself fit by exercising daily.', img: 'enc' },
      { title: 'enjoy your annual retreat with us!', img: 'family' },
    ]
  }),
}
</script>

<style scoped></style>